<template>
  <div
    :class="{ voluntaryRecruitmentDetail: true, heightStyle: photoShow }"
    class="titlesTop"
    v-if="volunteerData"
  >
    <headTitle :title="volunteerData.name">
      <!------- 双向打卡 扫描志愿者二维码 ---- -->
      <!-- <img
        src="../../assets/scanIcon.png"
        style="width:34%"
        v-if="codeShow"
        @click="toLocationScan"
      />-->
      <!------- 双向打卡 扫描志愿者二维码 ---- -->
      <i
        v-if="iconShow"
        style="margin-left:10px"
        class="iconfont icon-fenxiang1"
        @click="shareFun"
      />
    </headTitle>
    <div class="head_img">
      <img src="../../assets/images/zhaomu.png" alt />
    </div>
    <div class="title">
      <span>{{ volunteerData.activityName }}</span>
    </div>
    <ul class="volunteerList">
      <li>
        <span>所属组织</span>
        <span>{{ volunteerData.regionName }}</span>
      </li>
      <li>
        <span v-if="endTime">开始时间</span>
        <span v-if="!endTime">活动时间</span>
        <span>{{ volunteerData.activityBeginTime }}</span>
      </li>
      <li v-if="endTime">
        <span>结束时间</span>
        <span>{{ volunteerData.activityEndTime }}</span>
      </li>
      <li></li>
      <li v-if="!endTime">
        <span>具体时间段</span>
        <span>{{ speTime }}</span>
      </li>
      <li>
        <span>联系人</span>
        <span>{{ volunteerData.leaderName }}</span>
      </li>
      <li>
        <span>联系电话</span>
        <span>{{ volunteerData.leaderPhone }}</span>
      </li>
      <li>
        <span>活动地点</span>
        <span>{{ volunteerData.activityLocation }}</span>
      </li>
      <li>
        <span>招募人数</span>
        <span>{{ volunteerData.recruitPeople }}</span>
      </li>
    </ul>
    <div class="volunteerContent">
      <h3>活动简介</h3>
      <p>{{ volunteerData.summary }}</p>
    </div>
    <div v-if="signupShow" class="bottom_div">
      <span>
        已通过{{ memberData.recruit_pass }}/{{
        memberData.max_recruit
        }}人
      </span>
      <van-button
        v-show="btn_show"
        @click="joinActivity"
        type="primary"
        :loading="joinLoading"
        loading-type="spinner"
      >立即报名</van-button>
      <van-button
        v-show="!btn_show"
        @click="removeActivity"
        type="danger"
        :loading="cancelLoading"
        loading-type="spinner"
      >取消报名</van-button>
    </div>
    <van-overlay :show="success_show">
      <div class="wrapper" @click.stop>
        <div class="success_block">
          <div class="success_head">
            <img src="../../assets/images/baoming.png" alt />
          </div>
          <p>报名成功</p>
          <button @click="success_show = false">我知道了</button>
        </div>
      </div>
    </van-overlay>
    <div class="photo_title" v-if="camera_show">
      <p style="padding-bottom:10px">添加照片</p>
      <van-uploader
        v-model="allfiles"
        :disabled="is_disable"
        :before-read="beforeFileFUpload"
        :after-read="afterRead"
        @delete="onDelele"
      />
      <van-loading v-show="loadShow" class="loading" size="24px">上传中</van-loading>
    </div>
    <div class="show_photo" v-if="photoShow">
      <div v-for="(item, index) in photoList" :key="index">
        <img :src="item.originalMediaUrl" alt @click="lookBigPhoto(item.originalMediaUrl)" />
      </div>
    </div>

    <div v-if="volunteerData.status === 5" class="volunteerContent">
      <grading v-if="volunteerData" :status="volunteerData.status" :activityId="volunteerData.id"></grading>
      <!-- <div v-if="volunteerData">{{volunteerData}}</div> -->
    </div>
    <van-overlay :show="bigPhotoShow" @click="bigPhotoShow = false">
      <div class="photo_wrapper">
        <div>
          <img :src="overlayUrl" alt />
        </div>
      </div>
    </van-overlay>
    <!-- <button @click="finishAcitivity">完成活动</button> -->
  </div>
</template>

<script>
import Vue from "vue";
import SparkMD5 from "spark-md5";
import OSS from "ali-oss";
import { Button, Toast } from "vant";
import { reject } from "q";
import Recruitapi from "@/api/recruit";
import menuapi from "@/api/contentlibrary";
import tobbarVue from '../tobbar.vue';
import Grading from './Grading';
Vue.use(Button);
Vue.use(Toast);

export default {
  data() {
    return {
      scanList: {
        "scanType": 2, "activityName": "", "activityId": ""
      },//扫码传过去的数据
      speTime: '',
      endTime: '',
      codeShow: '',
      volunteerData: null,
      success_show: false,
      memberData: null, // 报名的人数情况
      joinLoading: false, // 报名按钮的加载状态
      iconShow: false,
      cancelLoading: false, // 取消报名按钮的加载状态
      // 包含活动id和用户id的参数
      params: {
        memberId: '',
        activityId: ''
      },
      btn_show: false, // 根据用户报名状态显示报名或取消报名按钮
      camera_show: false, //是否显示上传图片按钮
      photoShow: false, // 显示上传的图片，只有从我负责的活动点击进来才会显示这些图片
      signupShow: false,   // 是否显示报名按钮
      allfiles: [],
      is_disable: false, // 是否禁用上传图片按钮
      photoList: [],
      bigPhotoShow: false,
      overlayUrl: '', // 遮罩层中的url链接
      loadShow: false, // 上传loading显示
    };
  },
  components: {
    Grading,
  },
  created() {
    if (this.appName == "看埇桥") {
      this.iconShow = true;
    }
    this.token = JSON.parse(window.localStorage.userinfo).token
    // 文件上传配置文件
    this.client = new OSS({
      region: "oss-cn-hangzhou",
      accessKeyId: "LTAI4GHnMJwqTzsBkGQputTy",
      accessKeySecret: "UtZpduRDDcpUD6mKUseXDNjKeP5SxQ",
      bucket: "fmp-civ-test",
    });
  },
  mounted() {
    this.params.memberId = JSON.parse(window.localStorage.userinfo).id;
    this.params.activityId = this.$route.query.id;
    this.getVolList(this.params);
    this.getMemberCount(this.$route.query.id);
    this.getPhoto();
  },
  methods: {
    toLocationScan() {//扫码跳转

      if (this.appName == "今日和县" || this.appName == "爱旌德" || this.appName == "智慧新洲") {//uniapp嵌套

        window.uni.navigateTo({ url: '/pages/home/scanPage/scanPage?activityId=' + this.$route.query.id })
      } else {
        var u = navigator.userAgent;
        var msg
        msg = this.scanList
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
        if (isAndroid) {  //安卓
          window.javaInterface.toScanQR(JSON.stringify(msg));
        } else { //
          this.setupWebViewJavascriptBridge(function (bridge) {
            bridge.registerHandler("testJavascriptHandler", function (data, responseCallback) { //注册
              var responseData = { "Javascript Says": "Right back atcha!" };
              responseCallback(responseData);
            });
            bridge.callHandler("toScanQR", msg, function (response) {
              console.log(response);
            });
          });
        }
      }

    },
    //分享
    shareFun() {
      var describe = this.volunteerData.name.replace(/&nbsp;/g, "");
      var link;
      var imgSrc;
      link = window.location.href.split("#/")[0] + ".#/wxShare_voluntaryRec?id=" + this.volunteerData.id;
      if (this.appName === "看埇桥") {
        imgSrc = 'https://fmp-civ-test.oss-cn-hangzhou.aliyuncs.com/e88a8c9f7030efe57a7cda31eb3473b123456.png'
      } else {
        imgSrc = ''
      }
      this.shareContent({ url: link, title: describe, thumb: imgSrc, describe: describe });
      // this.shareContent({url: link,title:this.detailAll.title,thumb:this.detailAll.thumb.split(',')[0],describe:describe});
    },
    // 取消报名
    removeActivity() {
      if (!this.isToken()) { // 没用登入调到登入
        return false;
      }
      this.cancelLoading = true;
      Recruitapi.cancelActivity(this.params)
        .then((res) => {
          // console.log(res);
          if (res.success) {
            Toast("取消报名成功");
            this.getVolList(this.params);
          } else {
            Toast(res.message);
          }
          this.cancelLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 报名参加
    joinActivity() {
      if (!this.isToken()) { // 没用登入调到登入
        return false;
      }
      this.joinLoading = true;
      Recruitapi.sureActivity(this.params)
        .then((res) => {
          if (!res.success) {
            Toast(res.message);
          } else {
            this.success_show = true;
            this.getVolList(this.params);
          }
          this.joinLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 招募活动详情
    getVolList(params) {
      Recruitapi.getRecruitByIdSec(params)
        .then((res) => {
          // 判断是否显示照片上传框
          if (this.$route.query.signOfMy && res.data.status !== 5) {
            this.camera_show = true;
            this.codeShow = true
          }
          if (this.$route.query.signOfMy) {
            this.photoShow = true;
          }
          if (!this.$route.query.signOfMy && res.data.recruitType) {
            this.signupShow = true;
          } else {
            this.signupShow = false;
          }
          this.speTime = res.data.specificTimes.join(',');
          this.volunteerData = res.data;
          this.scanList.activityName = this.volunteerData.name;
          this.scanList.activityId = this.volunteerData.id;
          if (res.data.isSignUp) {
            this.btn_show = false;
          } else {
            this.btn_show = true;
          }
          if (this.volunteerData.activityEndTime) {
            this.endTime = true;
          } else {
            this.endTime = false
          }
          // 活动结束，禁用上传按钮
          if (res.data.status === 5) {
            this.is_disable = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 招募报名人数
    getMemberCount(id) {
      Recruitapi.getRecruitMemberById(id)
        .then((res) => {
          // console.log(res.data);
          this.memberData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取图片列表
    getPhoto() {
      Recruitapi.getPhotoList(this.params.activityId)
        .then((res) => {
          console.log(res.data);
          this.photoList = res.data;
          // this.memberData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查看大图
    lookBigPhoto(url) {
      this.overlayUrl = url;
      this.bigPhotoShow = true;
    },
    // 此时可以自行将文件上传至服务器
    afterRead(file) {
      // console.log(file);
    },
    // 文件上传前的判断
    beforeFileFUpload(file) {
      const fileMaxSize = 20 * 1024 * 1024;
      const fileType = file.type.substring(0, 5);
      var fileReader = new FileReader();
      // 获取文件后缀名
      let pointIndex = file.name.lastIndexOf(".");  //取到文件名最后一个点的坐标
      let nameLength = file.name.length;  // 获取文件名的长度
      file.suffix = file.name.substring(pointIndex + 1, nameLength);//截取获得后缀名
      let typeIndex = file.type.indexOf("/");  //取到类型中的第一个"/"
      let typeLength = file.type.length;  // 获取文件类型的长度
      file.fileType = file.type.substring(0, typeIndex);//截取获得文件类型名
      var spark = new SparkMD5();
      fileReader.readAsBinaryString(file);
      var that = this;
      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result);
        var md5 = spark.end();
        file.MD5 = md5;
      };
      // 上传前本地接口
      // console.log(file);
      if (file.size > fileMaxSize) {
        Toast("文件大小不能超过 20M");
        this.allfiles = [];
        return false;
      }
      if (file.fileType !== "image" && file.fileType !== "video") {
        Toast("请上传图片或视频");
        this.allfiles = [];
        console.log(this.allfiles);
        return false;
      }
      console.log(file);
      that.judgetFiles(file);
      return true;
    },
    // 验证通过，开始上传
    judgetFiles(file) {
      // 禁用上传框，显示加载
      this.is_disable = true;
      this.loadShow = true;
      const params = { activityId: this.params.activityId };
      var data = {
        mediaMD5: file.MD5,
        mediaName: file.name,
        mediaSize: file.size,
        mediaSuffix: file.suffix,
      };
      menuapi.judgetFile(params, data).then((res) => {
        // console.log(res);
        if (res.success) {
          file.mediaParentId = res.data.mediaParentId;
          file.regionId = res.data.regionId;
          file.userId = res.data.userId;
          if (res.data.originalMediaUrl) {
            file.Location = res.data.originalMediaUrl;
            // console.log('走上一步');
            this.fileInLibrary(file);
          } else {
            // console.log('走下一步');
            this.multipartUpload(file); // 上传
          }
        } else {
          Toast(res.message)
        }
      });
    },
    // 定义上传方法
    async multipartUpload(file) {
      try {
        const result = await this.client.multipartUpload(
          file.MD5 + "." + file.suffix,
          file,
          {
            meta: { year: 2020, people: "test" },
            mime: file.fileType,
          }
        );
        console.log(result.res);
        const arrs = this._getResult(result.res.requestUrls);  // 返回的url数组
        this._fileSuccess(file, arrs[0]);
        // console.log(file);
      } catch (e) {
        console.log(e);
        Toast("上传失败");
      }
    },
    // 截取数组地址
    _getResult(resUrl) {
      const _arr = [];
      for (let i = 0; i < resUrl.length; i++) {
        _arr.push(resUrl[i].split("?")[0]);
      }
      return _arr;
    },
    _fileSuccess(file, url) {
      file.Location = url; // 到内容库
      this.fileInLibrary(file); // 入库
    },
    // 入内容库
    fileInLibrary(file) {
      var data = {
        mediaMD5: file.MD5,
        mediaName: file.name,
        mediaParentId: file.mediaParentId,
        regionId: file.regionId,
        userId: file.userId,
        mediaSize: file.size,
        mediaSuffix: file.suffix,
        originalMediaUrl: file.Location,
      };
      // console.log(data);
      menuapi
        .warehousing(data)
        .then((res) => {
          console.log(res);
          if (res.success) {
            Toast('上传完成')
            this.finishAcitivity();
            this.is_disable = false;
            this.loadShow = false;
          } else {
            Toast("上传失败,请重新上传!");
            this.is_disable = false;
            this.loadShow = false;
          }
        })
        .catch(() => {
          Toast("上传失败,请重新上传!");
          this.is_disable = false;
          this.loadShow = false;
        });
    },
    // 完成活动
    finishAcitivity() {
      this.allfiles = [];
      this.getPhoto();
    },
    onDelele() {
      this.loadShow = false;
      this.is_disable = false;
    }
  },
};
</script>
<style lang="less" scoped>
.icon-fenxiang1 {
  font-size: 20px;
}
.voluntaryRecruitmentDetail {
  background: #fff;
  font-size: 0.28rem;
  // padding: 3vw 3vw 8vw 3vw;
  padding-bottom: 3vw;
  padding-left: 3vw;
  padding-right: 3vw;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 0.36rem;
    font-weight: 700;
  }
  .head_img {
    margin-top: 3vw;
    img {
      width: 100%;
      height: 25vw;
    }
  }
  .volunteerList {
    color: rgb(94, 94, 94);
    li {
      font-size: 4vw;
      margin: 0.2rem 0;
      span {
        display: inline-block;
        &:nth-child(1) {
          width: 1.5rem;
          color: #8a8a8a;
          margin-right: 5vw;
        }
      }
    }
  }
  .volunteerContent {
    // height: 100vw;
    h3 {
      position: relative;
      // padding-left: 0.2rem;
      margin: 0.2rem 0;
      // &::before {
      //     content: "";
      //     display: blcok;
      //     width: 0.1rem;
      //     height: 0.4rem;
      //     background-color: #f40;
      //     position: absolute;
      //     left: 0px;
      // }
    }
    p {
      word-break: break-all;
      color: #707070;
      line-height: 6.5vw;
      // padding-bottom: 38vw;
    }
  }
  .bottom_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 10px 20px 10px;
    button {
      // width: 25vw;
      height: 7vw;
      border-radius: 15px;
      border: none;
      line-height: 7vw;
      // background: #36B313;
      color: #fff;
    }
  }
  .photo_title {
    font-size: 1.17em;
    font-weight: bold;
    margin-top: 10vw;
    position: relative;
    .loading {
      position: absolute;
      bottom: 35px;
    }
  }
  .show_photo {
    // background: red;
    // margin-top: 15vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // justify-content: space-between;
    img {
      width: 28vw;
      margin: 3px;
    }
  }
  .photo_wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 100vw;
      }
    }
  }
  .wrapper {
    .success_block {
      display: flex;
      justify-content: center;
      flex-flow: column;
      align-items: center;
      background: #fff;
      width: 80vw;
      margin: 50% auto 0;
      border-radius: 10px;
      .success_head {
        width: 40vw;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4vw;
        img {
          width: 50vw;
        }
      }
      p {
        font-size: 7vw;
        color: #00a0e9;
        font-weight: bold;
        margin-bottom: 1vw;
      }
      button {
        border-radius: 6px;
        border: none;
        width: 60vw;
        height: 10vw;
        line-height: 10vw;
        text-align: center;
        background: #00a0e9;
        color: #fff;
        font-size: 4vw;
        margin: 5vw 0;
      }
    }
  }
  .btn {
    justify-self: flex-end;
  }
}
.heightStyle {
  height: auto;
}
</style>