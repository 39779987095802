<template>
  <div class="xinjipingren">
    <div class="act_gra">
      <h3>活动评价</h3>
      <img
        v-if="gradeShow"
        src="../../assets/images/grade.png"
        class="h_btn"
        alt
        @click="show = true"
      />
    </div>
    <van-action-sheet v-model="show" :round="false" title="活动评分">
      <div class="wrapper">
        <div class="c_rate">
          <van-rate
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
            v-model="activityScore"
            @change="onChange"
          />
          <span>{{ describe }}</span>
        </div>
        <div class="c_pop">
          <textarea
            style="border: 0; resize: none; margin-top: 10px"
            cols="28"
            rows="8"
            v-model="activityOpinion"
            placeholder="请输入活动评价"
          ></textarea>
          <div class="c_btn" @click="onComment">评分</div>
        </div>
      </div>
    </van-action-sheet>
    <div v-for="(item, index) in replyList" :key="index" class="card_box">
      <div class="myInformation">
        <!-- 头像 -->
        <div class="headPortrait">
          <img v-if="item.memberAvatar" :src="item.memberAvatar" alt />
          <img v-else src="../../assets/Headportrait.png" alt />
        </div>
        <div class="nameAndTime">
          <p>{{ item.memberName }}</p>
          <p class="smallTime">{{ item.createTime }}</p>
        </div>
      </div>
      <!-- <van-rate
        v-model="item.activityScore"
        :size="16"
        color="#ffd21e"
        void-icon="star"
        void-color="#eee"
      />-->
      <van-icon
        name="star"
        v-for="(itemson,indexson) in 5"
        :key="indexson"
        class="xinxin"
        :class="{'choosed':(indexson+1)<=item.activityScore}"
      />
      <div class="textContent" v-if="item.activityOpinion">{{ item.activityOpinion }}</div>
    </div>
    <div v-show="moreBtn" class="btn_wra">
      <van-button
        :loading="btnLoading"
        loading-type="spinner"
        class="mor_btn"
        @click="showMoreGrade"
      >展示更多评价</van-button>
    </div>
    <div v-if="empty" class="emp_txt">暂无更多数据</div>
  </div>
</template>

<script>
import { Toast } from "vant";
import RecruitApi from "@/api/recruit";

export default {
  props: ["activityId", "status"],
  data() {
    return {
      // myname: "评分组件",
      id: "",
      show: false, // 评分面板
      activityOpinion: "",
      activityScore: 0,
      scoreObj: {
        1: "很糟糕",
        2: "差",
        3: "一般",
        4: "优秀",
        5: "非常棒!",
      },
      describe: "",
      page: 1,
      replyList: [],
      btnLoading: false,
      moreBtn: false,
      empty: false,
      gradeShow: false,
    };
  },
  mounted() {
    this.id = this.activityId;
    this.queryGradingList(this.id);
    if (this.status === 5) {
      this.gradeShow = true;
    }
  },
  methods: {
    // 发表评价
    onComment() {
      if (!this.activityScore) {
        Toast("请选择星级");
        return;
      }
      // if (!this.activityOpinion) {
      //   Toast("请输入活动评价");
      //   return;
      // }
      const activityOpinionDTO = {
        activityId: this.activityId,
        activityOpinion: this.activityOpinion,
        activityScore: this.activityScore,
      };
      console.log(activityOpinionDTO);
      RecruitApi.addGrade(activityOpinionDTO, {
        appName: this.appName,
        memberId: JSON.parse(window.localStorage.userinfo).id,
      })
        .then((resp) => {
          if (resp.success) {
            // console.log(resp);
            Toast(resp.message);
            this.activityOpinion = "";
            this.activityScore = 0;
            this.queryGradingList(this.id);
            this.show = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询活动评价
    queryGradingList(id) {
      RecruitApi.getGrade({
        page: this.page,
        rows: 10,
        activityId: id,
        appName: this.appName,
      })
        .then((res) => {
          if (res.success) {
            this.btnLoading = false;
            if (res.data.items && res.data.items.length > 0) {
              if (this.page === 1) {
                this.replyList = [];
              }
              this.replyList = this.replyList.concat(res.data.items);
            }
            if (res.data.items.length < 10) {
              this.moreBtn = false;
              this.empty = true;
            } else {
              this.moreBtn = true;
              this.empty = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showMoreGrade() {
      this.page += 1;
      this.btnLoading = true;
      this.queryGradingList(this.id);
    },
    onChange(value) {
      this.describe = this.scoreObj[value];
    },
  },
};
</script>

<style lang="less" scoped>
.xinjipingren{
  /deep/.xinxin {
    color: rgb(238, 238, 238);
  }
  /deep/.choosed {
    color: rgb(255, 210, 30);
  }
}
.act_gra {
  display: flex;
  align-items: center;
}
.myInformation {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 0 8px 0;
  .headPortrait {
    width: 30px;
    height: 30px;
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  .nameAndTime {
    margin-left: 5px;
    font-size: 13px;
    width: calc(100% - 70px);
    .smallTime {
      font-size: 10px;
      color: #afafaf;
      margin-top: 2px;
    }
  }
  .screeningReport {
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    color: #999;
    span {
      font-size: 10px;
    }
    .iconfont {
      font-size: 10px;
    }
  }
}
.textContent {
  width: 100%;
  font-size: 16px;
  padding: 2px 0 4px;
  border-bottom: 1px solid #eee;
  word-break: break-all;
}
.h_btn {
  // display: block;
  // position: fixed;
  // top: 50vh;
  // right: 10px;
}
.wrapper {
  padding: 0 0 30px 20px;
  .c_rate {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    span {
      margin-left: 15px;
      color: #f8bd89;
    }
  }
  .c_pop {
    display: flex;
    align-items: flex-end;
    .c_btn {
      background: red;
      width: 50px;
      color: #fff;
      height: 25px;
      line-height: 25px;
      border-radius: 5px;
      text-align: center;
      margin-left: 8px;
    }
  }
}
.btn_wra {
  width: 50vw;
  margin: 2vw auto 0;
  .mor_btn {
    width: 100%;
    background: #1989fa;
    color: #fff;
    height: 30px;
    line-height: 30px;
  }
}
.emp_txt {
  text-align: center;
  color: rgba(168, 166, 166, 0.863);
  font-size: 14px;
  padding: 4px 0;
}
textarea {
  padding: 4px 0 0 4px;
  background: #eee;
}
textarea::-webkit-input-placeholder {
  color: #ddd;
}
</style>